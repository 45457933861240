.flex{
  display: flex;
}
.flex-column{
  flex-direction: column;
}
.wrap {
  flex-wrap: wrap;
}
.flex-justify-content{
  justify-content: center;
}
.flex-center{
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
}
.flex-v-center{
  align-items: center;
}
.flex-v-end{
  align-items: flex-end;
}
.flex-j-end {
  justify-content: flex-end;
}
.flex-j-start {
  justify-content: flex-start;
}

.flex-h-between{
  justify-content: space-between;
}
.flex-around{
  justify-content: space-around;
}

.blue{ 
  color: #41ACEF;
}
.red {
  color: #F95B46;
}
.cyan {
  color: #5ad6bb;
}
.green {
  color: #1fbb65;
}
.white {
  color: #fff;
}

.bigSize{
  font-weight: bold;
}

.cursor {
  cursor: pointer;
}
.not-allowed {
  cursor: not-allowed;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.relative {
  position: relative;
}
.Abbreviation {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.line-clamp {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.none {
  opacity: 0;
  z-index: -1;
}

.absolute {
  position: absolute;
}
.transform {
  transform: rotate(180deg);
  box-shadow: 10px 2px 16px 0px rgba(108, 125, 178, 0.5), -7px -4px 20px 0px rgba(15,15,38,0.4);
}

.flex-1 {
  flex: 1;
}
.borderbox {
  box-sizing: border-box;
}

.marginTop50 {
  margin-top: 50px;
}